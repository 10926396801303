import React, { useCallback, useEffect } from 'react'
import { useMyContext } from '../Context/MyContextProvider';
import { useDispatch } from 'react-redux';
import { setSetting } from '../store/setting/actions';
import { logout } from '../store/slices/authSlice';
import axios from 'axios';

const getTimeoutDuration = (role) => {
    switch (role) {
        case 'User':
            return 5 * 60 * 1000; // 5 minutes
        case 'Scanner':
        case 'Agent':
        case 'Organizer':
            return 4 * 60 * 60 * 1000; // 4 hours
        default:
            return null;
    }
};

function AppContent({ children }) {
    const { userRole, api } = useMyContext();
    const dispatch = useDispatch();
    const [logoutTimer, setLogoutTimer] = React.useState(null);

    dispatch(setSetting());

    const handleLogout = useCallback(async () => {
        dispatch(logout());
    }, [dispatch]);

    useEffect(() => {
        // Only set up timer if user is logged in
        if (!userRole) return;

        const timeoutDuration = getTimeoutDuration(userRole);
        if (!timeoutDuration) return;

        const resetTimer = () => {
            if (logoutTimer) clearTimeout(logoutTimer);
            setLogoutTimer(setTimeout(handleLogout, timeoutDuration));
        };

        const activityEvents = [
            'mousedown',
            'mousemove',
            'keydown',
            'scroll',
            'touchstart'
        ];

        activityEvents.forEach(event => {
            document.addEventListener(event, resetTimer);
        });

        resetTimer();

        return () => {
            if (logoutTimer) clearTimeout(logoutTimer);
            activityEvents.forEach(event => {
                document.removeEventListener(event, resetTimer);
            });
        };
    }, [logoutTimer, userRole, handleLogout]);

    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };
        document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    // useEffect(() => {
    //     const fetchUserCount = async () => {
    //         try {
    //             await axios.get(`${api}store-device`);
    //         } catch (error) {
    //         }
    //     };
    //     fetchUserCount();
    // }, []);

    useEffect(() => {
        // Function to get the user's location
        const getUserLocation = () => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                const { latitude, longitude } = position.coords;
    
                try {
                  // Fetch detailed location info from BigDataCloud API
                  const response = await axios.get(
                    `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
                  );
    
                  const locationDetails = response.data;
    
                  // Send location details to your API
                  await axios.post(`${api}store-device`, {
                    latitude,
                    longitude,
                    city: locationDetails.city,
                    locality: locationDetails.locality,
                    country: locationDetails.countryName,
                    principalSubdivision: locationDetails.principalSubdivision,
                  });
    
                  console.log("Location details sent successfully:", locationDetails);
                } catch (error) {
                  console.error("Error fetching or sending location details:", error);
                }
                localStorage.setItem("locationSent", "true");
              },
              (error) => {
                console.error("Error getting location:", error.message);
              }
            );
          } else {
            console.error("Geolocation is not supported by this browser.");
          }
        };
const locationSent=localStorage.getItem("locationSent")
        if(!locationSent){
            getUserLocation();
        }    

      }, []);



    return (
        <div className="App">
            {children}
        </div>
    );
}

export default AppContent
