import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import CountUp from "react-countup";

const SalesCard = ({ title, value, subtitle, link = 'Total' }) => (
  <Card className="card-block card-stretch card-height">
    <Card.Body>
      <div className="mb-2 d-flex justify-content-between align-items-center">
        <span className="text-dark">{title}</span>
        <Link className="badge rounded-pill bg-primary-subtle" to="#javascript:void(0);">
          {link}
        </Link>
      </div>
      <h2 className="counter">₹<CountUp start={0} end={value} duration={3} useEasing separator="," /></h2>
      {subtitle && <small>{subtitle}</small>}
    </Card.Body>
  </Card>
);

const GraphAndCardsLayout = ({ graphTitle, graphValue, chartOptions, chartSeries, cards }) => (
  <Row>

    <Col lg="3" md="6">
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <div className="d-flex align-items-start justify-content-between mb-2">
            <p className="mb-0 text-dark">{graphTitle}</p>
            <Link className="badge rounded-pill bg-primary-subtle" to="#javascript:void(0);">
              View
            </Link>
          </div>
          <div className="mb-3">
            <h2 className="counter">₹
              <CountUp start={0} end={graphValue} duration={3} useEasing={true} separator="," />
            </h2>
          </div>
          <div>
            <Chart options={chartOptions} series={chartSeries} type="bar" height="100%" />
          </div>
        </Card.Body>
      </Card>
    </Col>
    <Col lg="9" md="6">
      <Row>
        {cards.map((data, index) => (
          <Col lg="3" md="6" key={index}>
         
            <SalesCard
              title={data.title}
              value={data.amount}
              subtitle={data.note}
            />
          </Col>
        ))}
      </Row>
    </Col>
  </Row>
);

export default GraphAndCardsLayout;
